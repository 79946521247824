<template>
    <!-- 预约拍照-支付页-核对订单组件 -->
    <div class="order_check_box">
        <!-- 头部 -->
        <div class="order_check_top">
            <el-row>
                <!-- 标题 -->
                <el-col :span="24">
                    <div class="order_check_title">核对订单</div>
                </el-col>
                <!-- 订单号 -->
                <el-col :span="12">
                    <div class="order_check_number">
                        <span>订单号：</span>
                        <span>{{order_detail.orderID}}</span>
                    </div>
                </el-col>
                <!-- 创建时间 -->
                <el-col :span="12">
                    <div class="order_check_createtime">
                        <span>创建时间：</span>
                        <span>{{order_detail.orderTime}}</span>
                    </div>
                </el-col>
            </el-row>
        </div>
            
        <!-- 内容 -->
        <div class="order_check_content">
            <!-- 标题 -->
            <div class="order_check_content_title">个人信息</div>
            <!-- 个人信息 -->
            <div class="order_check_content_info">
                <el-form 
                    :inline="true" 
                    :model="form" 
                    :rules="rules" 
                    class="user_info">
                    <el-form-item label="姓名：" prop="name">
                        <el-input v-model="form.name"
                        :disabled="order_detail.userID != user_id">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="联系电话：" prop="mobile">
                        <el-input v-model="form.mobile"
                            :disabled="order_detail.userID != user_id">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="联系地址：" prop="address"
                        v-if="$route.name == 'clothesPay'">
                        <el-input v-model="form.address"
                            :disabled="order_detail.userID != user_id">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <!-- 标题 -->
            <div class="order_check_content_title">时间</div>
            <!-- 拍摄时间 -->
            <div class="order_check_time_box">
                <!-- 开始时间 -->
                <div class="order_check_time"
                    v-if="order_detail.beginTime">
                    <div class="order_detail_time">
                        {{order_detail.beginTime[1]}}
                    </div>
                    <div class="order_date_week">
                        <span class="order_start_date">
                            {{order_detail.beginTime[0]}}
                        </span>
                        <span>
                            {{order_detail.beginTime[1]}}
                        </span>
                    </div>
                </div>
                <template v-if="$route.path.indexOf('photograph')<0">
                    <hr style="width: 100px;display: inline-block;margin: 20px">
                    <!-- 结束时间 -->
                    <div class="order_check_time"
                        v-if="order_detail.endTime">
                        <div class="order_detail_time">
                            {{order_detail.endTime[1]}}
                        </div>
                        <div class="order_date_week">
                            <span>{{order_detail.endTime[0]}}</span>
                            <span>
                                {{order_detail.endTime[1]}}
                            </span>
                        </div>
                    </div>
                </template>
            </div>
            <!-- 标题 -->
            <div class="order_check_content_title">订单详情</div>
            <!-- 订单详情 -->
            <div class="order_check_detail_box">
                <order-check-lease
                    v-if="$route.name == 'clothesPay'">
                </order-check-lease>
                <order-check-photograph
                    v-if="$route.name == 'photographPay'">
                </order-check-photograph>
                <order-check-field
                    v-if="$route.name == 'fieldPay'">
                </order-check-field>
            </div>
			<!-- 快递单号 -->
			<!-- <div class="order_check_detail_box">
				<order-logistics
				v-if="$route.name == 'clothesPay'">
				</order-logistics> 
			</div> -->
            <template v-if="order_detail.userID == user_id">
                <!-- 标题 -->
                <div class="order_check_content_title">选择支付方式</div>
                <!-- 支付方式 -->
                <div class="order_check_payment_box">
                    <el-radio-group
                        v-model="radio">
                        <el-radio :label="1">
                            <img :src="require('@/assets/images/photograph/@1x/icon_reserve.png')" alt="">
                            <span class="order_check_payment">账户余额（剩余{{order_detail.balance}}）</span>
                        </el-radio>
                        <el-radio :label="2">
                            <img :src="require('@/assets/images/photograph/@1x/wechatpay.png')" alt="">
                            <span class="order_check_payment">微信支付</span>
                        </el-radio>
                        <el-radio :label="3">
                            <img :src="require('@/assets/images/photograph/@1x/alipay.png')" alt="">
                            <span class="order_check_payment">支付宝</span>
                        </el-radio>
                    </el-radio-group>
                </div>
                <!-- 标题 -->
                <div class="order_check_content_footer">
                    <span>应付总额：</span>
                    <span class="order_check_content_footer_price">{{"¥"+order_detail.all_price}}</span>
                </div>
            </template>
        </div>
        <!-- 付款按钮 -->
        <div class="order_check_pay_box"
            v-if="order_detail.userID == user_id">
            <el-button 
                type="danger"
                @click="payBtn"
                class="order_check_pay_btn">付款</el-button>
        </div>
        <el-dialog
            :visible="dialogVisible"
            @close="handleCloseDialog"
            width="300px"
            :show-close="false"
            center
            class="order_check_dialog">
            <div style="text-align: center">
                <div class="order-check-dialog-wechat" v-if="radio == 2">扫码支付</div>
                <img :src="require('@/assets/images/photograph/@1x/icon_success.png')" alt="" v-if="radio == 3">
                <vue-qr
                    :text="pay_src"
                    :size="200"
                    :margin="0"
                    :auto-color="true"
                    :dotScale="1"
                    v-else-if="radio == 2">
                </vue-qr>
                <el-button
                    type="warning"
                    class="check_dialog"
                    @click="handleCloseDialog">
                    <span>完成支付</span>
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import VueQr from 'vue-qr';

export default {
    data() {
        return {
            // 表单
            form: {
                name: "",
                mobile: "",
                address: "",
            },
            // 表单验证
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur'
                    }
                ],
                mobile: [
                    {
                        required: true,
                        message: '请输入联系电话',
                        trigger: 'blur'
                    }
                ],
                address: [
                    {
                        required: true,
                        message: '请输入地址',
                        trigger: 'blur'
                    }
                ],
            },
            // 支付二维码参数
            pay_src: "",
            // 支付方式单选框
            radio: 3,
        }
    },
    computed: mapState({
        dialogVisible: state => state.dialogVisible,
        order_detail: state => state.order.order_detail,
        user_id: state => state.user.user_id
    }),
    components: {
        orderCheckLease: () => import("@/components/orderCheckLease"),
		// orderLogistics : () => import("@/components/orderLogistics "),
        orderCheckPhotograph: () => import("@/components/orderCheckPhotograph"),
        orderCheckField: () => import("@/components/orderCheckField"),
        VueQr
    },
    methods: {
        ...mapMutations([
            "changeDialogVisible"
        ]),
        ...mapActions([
            "handleConfirmOrderInfo",
            "handleWechatPay"
        ]),
        setInfo(val){
            this.form = {
                name: val.userName,
                mobile: val.userPhone,
                address: val.address || ""
            }
        },
        handleCloseDialog(){
            this.changeDialogVisible(false);
            this.$router.go(0)
        },
        payBtn(){
            this.$confirm("是否确认个人信息无误？",{
                cancelButtonText: "取消",
                confirmButtonText: "确定",
                type: "warning"
            }).then(() => {
                let url = "",
                type = "";
                switch (this.$route.name) {
                    case "clothesPay":
                        type = 3;
                        break;
                    case "photographPay":
                        type = 1;
                        break;
                    case "fieldPay":
                        type = 2;
                        break;
                }
                this.handleConfirmOrderInfo({
                    orderID: this.order_detail.orderID,
                    userName: this.form.name, 
                    userPhone: this.form.mobile,
                    address: this.form.address,
                    type
                })
                if(this.radio == 3){
                    url = this.$_url_.baseUrl + "payment/alipay?userID=" + this.user_id + "&orderID=" + this.order_detail.orderID + "&type=" + type;
                    window.open(url);
                    this.changeDialogVisible(true);
                }else if(this.radio == 2){
                    this.handleWechatPay({
                        type,
                        orderID: this.order_detail.orderID,
                    }).then((res) => {
                        this.pay_src = res;
                        this.changeDialogVisible(true);
                    })
                }else{
                    this.$message({
                        showClose: true,
                        message: "该支付方式暂未开通！",
                        type: "warning"
                    })
                }
            }).catch(() => {})
        }
    },
    created() {
        this.setInfo(this.order_detail)
    },
    watch: {
        order_detail(val){
            this.setInfo(val)
        }
    },
}
</script>

<style scoped>
/* element-ui */
.order_check_top .el-col,
.order_check_detail_box>>>.el-row>.el-col{
    margin-bottom: 10px;
}

/* 头部 */
/* 头部标题 */
.order_check_title{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}
/* 创建时间 */
.order_check_createtime{
    text-align: right;
}
/* 订单内容 */
.order_check_content{
    border: 1px solid #909399;
}
/* 订单内容标题 */
.order_check_content_title{
    background-color: #DCDFE6;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
}
/* 个人信息盒子 */
.order_check_content_info{
    padding-left: 20px;
    padding-top: 25px;
}
/* 用户信息表单 */
.user_info>div{
    margin-right: 30px;
}
/* 拍摄时间 */
.order_check_time_box{
    padding: 10px 20px;
}
.order_check_time{
    display: inline-block;
}
/* 详细时间 */
.order_check_time>.order_detail_time{
    font-size: 25px;
    text-align: center;
}
/* 日期 & 星期 */
.order_date_week>span{
    margin-right: 10px;
}
/* 订单详情盒子 */
.order_check_detail_box{
    padding: 10px 20px;
}
/* 支付方式盒子 */
.order_check_payment_box{
    padding: 30px 20px;
}
/* 支付方式文字 */
.order_check_payment{
    margin-left: 10px;
    color: #606266 !important;
}
/* 底部价格总计 */
.order_check_content_footer{
    background-color: #DCDFE6;
    padding: 20px 20px;
    text-align: right;
}
/* 价格 */
.order_check_content_footer_price{
    font-size: 25px;
    font-weight: bold;
    color: red;
}
/* 付款按钮 */
.order_check_pay_box{
    text-align: right;
    padding: 20px 0;
}
.order_check_pay_btn{
    width: 200px;
    height: 60px;
}
.order_check_dialog>>>.el-dialog__header{
    padding: 0;
}
.check_dialog{
    width: 100%;
    margin-top: 15px;
}
.order-check-dialog-wechat{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}
</style>
